import React from 'react'
import { GoogleApiWrapper } from 'google-maps-react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

const PlacesSearch = ({ onPlaceSelect }) => {

  return (
    <GooglePlacesAutocomplete
      onSelect={onPlaceSelect}
      placeholder="Search by location"
    />
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_API_KEY
})(PlacesSearch)
