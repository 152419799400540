import React, { Component } from 'react'
// import styled from 'styled-components';
import { Link } from 'gatsby'
// import Img from "gatsby-image";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'

const mapStyles = require("./map-style.json");


export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    node: {},
    center: {}
  }

  UNSAFE_componentWillUpdate({ center }, prev){
    if(center.lat !== prev.center.lat || center.lng !== prev.center.lng){
      this.setState({ center })
    }
  }

  onMarkerClick = (props, marker, e) => {
    const node = this.props.nodes.find(n => n.title === props.title)
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      center: node.geo,
      node
    })
  }

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  }

  render() {
  
    
    const { google, nodes } = this.props
    const { activeMarker, showingInfoWindow, node, center } = this.state
    return (
        
            <Map
        google={google}
        zoom={5}
        center={center}
        styles={mapStyles}
        style={{width: '100%', height: '100%', position: 'relative'}}
        disableDefaultUI={true}
        zoomControl={true}
        scrollwheel={false}>

        {nodes.map(node =>
          <Marker
            key={node.id}
            onClick={this.onMarkerClick}
            title={node.title}
            name={node.title}
            position={node.geo}
            icon={require("./mark.svg")}
          />

          
          )}

        <InfoWindow
          marker={activeMarker}
          visible={showingInfoWindow}
          onClose={this.onClose}>
          <div>
            <Link to={`/overviews/${node.slug}`}>
              <h4>{node.title}</h4>
              <img
                style={{height: '400px', width: '400px'}}
                src={node.image && node.image.fluid.srcSet}
                alt={node.title} />
            </Link>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_API_KEY
})(MapContainer)
